import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { AddCustomer } from '../../container/pages/style';
import Cookies from 'js-cookie';

const Dashboard = lazy(() => import('../../container/dashboard'));
const Ecommerce = lazy(() => import('../../container/dashboard/Ecommerce'));
const Business = lazy(() => import('../../container/dashboard/Business'));
const Performance = lazy(() => import('../../container/dashboard/Performance'));
const CRM = lazy(() => import('../../container/dashboard/CRM'));
const AddPins = lazy(() => import('../../container/pages/AddPins'));
const UserList = lazy(() => import('../../components/Pages-Dashboard/UserList'));
const AddUser = lazy(() => import('../../components/Pages-Dashboard/AddUsers'));
const ViewUser = lazy(() => import('../../components/Pages-Dashboard/ViewUsers'));

const EditUser = lazy(() => import('../../components/Pages-Dashboard/EditUsers'));
const ProductList = lazy(() => import('../../components/Pages-Dashboard/ProductList'));
const AddProduct = lazy(() => import('../../components/Pages-Dashboard/AddProduct'));
const EditProduct = lazy(() => import('../../components/Pages-Dashboard/EditProduct'));
const ViewProduct = lazy(() => import('../../components/Pages-Dashboard/ViewProduct'));
//
const EntityList = lazy(() => import('../../components/Pages-Dashboard/Entity'));
const AddEntity = lazy(() => import('../../components/Pages-Dashboard/AddEntity'));
const AddMake = lazy(() => import('../../components/Pages-Dashboard/AddMake.js'));
const AddBase = lazy(() => import('../../components/Pages-Dashboard/AddBase.js'));
const AddBody = lazy(() => import('../../components/Pages-Dashboard/AddBody.js'));
const AddModel = lazy(() => import('../../components/Pages-Dashboard/AddModel.js'));
const AddSubModel = lazy(() => import('../../components/Pages-Dashboard/AddSubModel.js'));
const AddEngine = lazy(() => import('../../components/Pages-Dashboard/AddEngine.js'));
const AddTransmission = lazy(() => import('../../components/Pages-Dashboard/AddTransmission.js'));

const EditEntity = lazy(() => import('../../components/Pages-Dashboard/EditEntity'));
const EditMake = lazy(() => import('../../components/Pages-Dashboard/EditMake.js'));
const EditBase = lazy(() => import('../../components/Pages-Dashboard/EditBase.js'));
const EditBody = lazy(() => import('../../components/Pages-Dashboard/EditBody.js'));
const EditSubModel = lazy(() => import('../../components/Pages-Dashboard/EditSubModel.js'));
const EditModel = lazy(() => import('../../components/Pages-Dashboard/EditModel.js'));
const EditEngine = lazy(() => import('../../components/Pages-Dashboard/EditEngine.js'));
const EditTransmission = lazy(() => import('../../components/Pages-Dashboard/EditTransmission.js'));
const ViewEntity = lazy(() => import('../../components/Pages-Dashboard/ViewEntity'));

// Car Catalog
const MakeList = lazy(() => import('../../components/Pages-Dashboard/MakeList'));
const ModelList = lazy(() => import('../../components/Pages-Dashboard/ModelList'));
const SubModelList = lazy(() => import('../../components/Pages-Dashboard/SubModelList'));
const BaseList = lazy(() => import('../../components/Pages-Dashboard/BaseList'));
const EngineList = lazy(() => import('../../components/Pages-Dashboard/EngineList'));
const Transmission = lazy(() => import('../../components/Pages-Dashboard/Transmission'));
const BodyList = lazy(() => import('../../components/Pages-Dashboard/BodyList'));

const SubscriptionPlans = lazy(() => import('../../components/Pages-Dashboard/SubscriptionPlans'));
const AddSubscriptionPlan = lazy(() => import('../../components/Pages-Dashboard/AddSubscriptionPlan'));
// const EditSubscriptionPlan = lazy(() => import('../../components/Pages-Dashboard/EditSubscriptionPlan'));

const SubscribedUsers = lazy(() => import('../../components/Pages-Dashboard/SubscribedUsers'));
const AddSubscribedUser = lazy(() => import('../../components/Pages-Dashboard/AddSubscribedUser'));
const EditSubscribedUser = lazy(() => import('../../components/Pages-Dashboard/EditSubscribedUser'));
const ViewSubscribedUser = lazy(() => import('../../components/Pages-Dashboard/ViewSubscribedUser'));

const CategoryList = lazy(() => import('../../components/Pages-Dashboard/Categories'));
const AddCategoryList = lazy(() => import('../../components/Pages-Dashboard/AddCategory'));
const ViewCategoryList = lazy(() => import('../../components/Pages-Dashboard/ViewCategory'));
const EditCategoryList = lazy(() => import('../../components/Pages-Dashboard/EditCategory'));

const SubCategoryList = lazy(() => import('../../components/Pages-Dashboard/SubCategory'));
const AddSubCategory = lazy(() => import('../../components/Pages-Dashboard/AddSubCategory'));

// Orders
const Order = lazy(() => import('../../components/Pages-Dashboard/Orders'));
const AddOrder = lazy(() => import('../../components/Pages-Dashboard/AddOrder'));

//Coupon & Deals
const CouponDealsList = lazy(() => import('../../components/Pages-Dashboard/CouponDeals'));
const AddCouponDeals = lazy(() => import('../../components/Pages-Dashboard/AddCouponDeals'));
const ViewCouponDeals = lazy(() => import('../../components/Pages-Dashboard/ViewCouponDeals'));
const EditCouponDeals = lazy(() => import('../../components/Pages-Dashboard/EditCouponDeals'));

// Customers
const Customers = lazy(() => import('../../components/Pages-Dashboard/CustomersList'));
const AddCustomers = lazy(() => import('../../components/Pages-Dashboard/AddCustomer'));
const ViewCustomers = lazy(() => import('../../components/Pages-Dashboard/ViewCustomer'));
// const EditCustomers = lazy(() => import('../../components/Pages-Dashboard/EditCustomer'));

const Sellers = lazy(() => import('../../components/Pages-Dashboard/SellerList'));
const AddSellers = lazy(() => import('../../components/Pages-Dashboard/AddSeller'));
const ViewSellers = lazy(() => import('../../components/Pages-Dashboard/ViewSeller'));
const EditSellers = lazy(() => import('../../components/Pages-Dashboard/EditSeller'));

//pages
const Pages = lazy(() => import('../../components/Pages-Dashboard/Pages'));
const AddPages = lazy(() => import('../../components/Pages-Dashboard/AddPages'));
const ViewPages = lazy(() => import('../../components/Pages-Dashboard/ViewPages'));
const EditPages = lazy(() => import('../../components/Pages-Dashboard/EditPages'));

//Ticket
const Ticket = lazy(() => import('../../components/Pages-Dashboard/Ticket'));
const AddTicket = lazy(() => import('../../components/Pages-Dashboard/AddTicket'));
const ViewTicket = lazy(() => import('../../components/Pages-Dashboard/ViewTicket'));
const EditTicket = lazy(() => import('../../components/Pages-Dashboard/EditTicket'));

//Role & Permissions
const Roles_Permissions = lazy(() => import('../../components/Pages-Dashboard/Role_Permissions'));
const AddUsersRole = lazy(() => import('../../components/Pages-Dashboard/AddUsersRole'));

//Refferals
const Refferals = lazy(() => import('../../components/Pages-Dashboard/Refferals'));

//FAQ
const FaqList = lazy(() => import('../../components/Pages-Dashboard/FaqList'));
const AddFaq = lazy(() => import('../../components/Pages-Dashboard/AddFaq'));
const ViewFaq = lazy(() => import('../../components/Pages-Dashboard/ViewFaq'));
const EditFaq = lazy(() => import('../../components/Pages-Dashboard/EditFaq'));

//Settings
const GeneralSetting = lazy(() => import('../../components/Pages-Dashboard/settings/GeneralSetting'));
const LocalSettings = lazy(() => import('../../components/Pages-Dashboard/settings/LocalSettings'));
const HomePageSetting = lazy(() => import('../../components/Pages-Dashboard/settings/HomePageSetting'));
const EmailSetting = lazy(() => import('../../components/Pages-Dashboard/settings/EmailSetting'));
const PaymentSetting = lazy(() => import('../../components/Pages-Dashboard/settings/PaymentSetting'));
const BillandTaxSetting = lazy(() => import('../../components/Pages-Dashboard/settings/BillandTaxSetting'));
const ThemeSetting = lazy(() => import('../../components/Pages-Dashboard/settings/ThemeSetting'));

const Blog = lazy(() => import('../../components/Pages-Dashboard/BlogList.js'));
const AddBlog = lazy(() => import('../../components/Pages-Dashboard/AddBlog'));
const EditBlog = lazy(() => import('../../components/Pages-Dashboard/EditBlog'));

//Brand
const BrandList = lazy(() => import('../../components/Pages-Dashboard/BrandList'));
const AddBrand = lazy(() => import('../../components/Pages-Dashboard/AddBrand'));
const EditBrand = lazy(() => import('../../components/Pages-Dashboard/EditBrand'));

//Ads
const AdsList = lazy(() => import('../../components/Pages-Dashboard/AdsList'));
const AddAds = lazy(() => import('../../components/Pages-Dashboard/AddAds'));
const EditAds = lazy(() => import('../../components/Pages-Dashboard/EditAds'));
//Banners
const Domain = lazy(() => import('../../components/Pages-Dashboard/Domain.js'));
///
const BannersList = lazy(() => import('../../components/Pages-Dashboard/BannersList.js'));
const AddBanners = lazy(() => import('../../components/Pages-Dashboard/AddBanners.js'));
const EditBanners = lazy(() => import('../../components/Pages-Dashboard/EditBanners.js'));

//Banners
const LogoList = lazy(() => import('../../components/Pages-Dashboard/LogoList.js'));
const AddLogo = lazy(() => import('../../components/Pages-Dashboard/AddLogo.js'));
const EditLogo = lazy(() => import('../../components/Pages-Dashboard/EditLogo.js'));

//Meta Home
const MetaHomeList = lazy(() => import('../../components/Pages-Dashboard/MetaHomeList.js'));
const AddMetaHome = lazy(() => import('../../components/Pages-Dashboard/AddMetaHome.js'));
const EditMetaHome = lazy(() => import('../../components/Pages-Dashboard/EditMetaHome.js'));
// const ViewMetaHome = lazy(() => import('../../components/Pages-Dashboard/ViewCatalog'));

//Platform
const PlatformList = lazy(() => import('../../components/Pages-Dashboard/CatalogList'));
const AddPlatform = lazy(() => import('../../components/Pages-Dashboard/AddCatalog'));
const EditPlatform = lazy(() => import('../../components/Pages-Dashboard/EditCatalog'));
const ViewPlatform = lazy(() => import('../../components/Pages-Dashboard/ViewCatalog'));

// //
// const AddPost = lazy(() => import('../../container/pages/AddPost'));
// const EditPost = lazy(() => import('../../container/pages/EditPost'));
// const ViewPost = lazy(() => import('../../container/pages/ViewPost'));

// // const AddPins = lazy(() => import('../../container/pages/AddPins'));
// const StudentDashboard = lazy(() => import('../../container/dashboard/StudentDashboard'));
// const TeacherDashboard = lazy(() => import('../../container/dashboard/TeacherDashboard'));
// const StudentHome = lazy(() => import('../../container/dashboard/StudentDashboard'));
// const Tutoring = lazy(() => import('../../container/pages/Tutoring'));
// const Exam = lazy(() => import('../../container/pages/Exam'));
// const PastPapers = lazy(() => import('../../container/pages/PastPapers'));
// const AllCourses = lazy(() => import('../../container/pages/AllCourses.js'));
// const ActivePlan = lazy(() => import('../../container/pages/ActivePlan'));
// //

// const DefaultLink = lazy(() => import('../../container/pages/DefaultLink'));
// const EditPins = lazy(() => import('../../container/pages/EditPins'));
// const ViewPins = lazy(() => import('../../container/pages/ViewPins'));

// const EditUser = lazy(() => import('../../container/pages/EditUsers'));
// const ViewUser = lazy(() => import('../../container/pages/ViewUsers'));
// const UsersList = lazy(() => import('../../container/pages/UsersList'));
// const AddUserRole = lazy(() => import('../../container/pages/AddUsersRole'));
// const Roles_Permissions = lazy(() => import('../../container/pages/EditUsersRole'));
// const Payments = lazy(() => import('../../container/pages/Payments'));
// const Plans = lazy(() => import('../../container/pages/Plans'));

// const Pages = lazy(() => import('../../container/pages/Pages'));

// const SectionList = lazy(() => import('../../container/pages/SectionList'));
// const SkillList = lazy(() => import('../../container/pages/SkillList'));
// const TopicList = lazy(() => import('../../container/pages/TopicList'));
// const UserGroup = lazy(() => import('../../container/pages/UserGroup'));

// const CategoryList = lazy(() => import('../../container/pages/CategoryList'));
// const SubCategory = lazy(() => import('../../container/pages/SubCategory'));
// const TagsList = lazy(() => import('../../container/pages/TagsList'));

// const QuizzesList = lazy(() => import('../../container/pages/QuizzesList'));
// const CreateNewQuiz = lazy(() => import('../../container/pages/CreateNewQuiz'));
// const CreateNewExam = lazy(() => import('../../container/pages/CreateNewExam'));
// const EditNewQuiz = lazy(() => import('../../container/pages/EditNewQuiz'));
// const EditNewPage = lazy(() => import('../../container/pages/EditNewPage'));
// const AddNewPage = lazy(() => import('../../container/pages/AddNewPage'));
// const ViewNewPage = lazy(() => import('../../container/pages/ViewNewPage'));
// const EditNewExam = lazy(() => import('../../container/pages/EditNewExam'));
// const OverAllReportQuiz = lazy(() => import('../../container/pages/OverAllReportQuiz'));
// const OverAllReportPractice = lazy(() => import('../../container/pages/OverAllReportPractice'));
// const QuizDetailedReport = lazy(() => import('../../container/pages/QuizDetailedReport'));
// const OverAllReportExam = lazy(() => import('../../container/pages/OverAllReportExam'));
// const ExamDetailedReport = lazy(() => import('../../container/pages/ExamDetailedReport'));

// const ExamList = lazy(() => import('../../container/pages/ExamList.js'));
// const CreatePractice = lazy(() => import('../../container/pages/CreatePractice'));
// const QuizType = lazy(() => import('../../container/pages/QuizType.js'));
// const ExamType = lazy(() => import('../../container/pages/ExamType.js'));
// const AddSquenceQuestion = lazy(() => import('../../container/pages/AddSquenceQuestion.js'));
// const AddFillTheBlanks = lazy(() => import('../../container/pages/AddFillTheBlanks.js'));
// const AddMultipleQuestions = lazy(() => import('../../container/pages/AddMultipleQuestions.js'));

// const PracticeSets = lazy(() => import('../../container/pages/PracticeSets.js'));

// const PracticeLessonList = lazy(() => import('../../container/pages/PracticeLessonList.js'));
// const CreatePracticeLesson = lazy(() => import('../../container/pages/CreatePracticeLesson'));
// const PracticeVideoList = lazy(() => import('../../container/pages/PracticeVideoList.js'));
// const CreatePracticeVideo = lazy(() => import('../../container/pages/CreatePracticeVideo'));

// const Lessions = lazy(() => import('../../container/pages/ExamType.js'));
// const Videos = lazy(() => import('../../container/pages/ExamType.js'));
// const LessionsBank = lazy(() => import('../../container/pages/LessonBank.js'));

// //******************* Question Bank Starts ********************************************/
// const ImportQuestions = lazy(() => import('../../container/pages/ImportQuestions'));
// const ComprehensionsList = lazy(() => import('../../container/pages/ComprehensionsList'));
// const QuestionsTypes = lazy(() => import('../../container/pages/QuestionsTypes'));
// const QuestionList = lazy(() => import('../../container/pages/QuestionList'));
// const AddQuestionsSingle = lazy(() => import('../../container/pages/AddQuestionsSingle'));
// const EditQuestions = lazy(() => import('../../container/pages/EditQuestions'));
// const Addshortquestion = lazy(() => import('../../container/pages/Addshortquestion'));
// const AddQuestionType = lazy(() => import('../../container/pages/AddQuestionType'));
// const EditQuestionsType = lazy(() => import('../../container/pages/EditQuestionsType'));
// const EditComprehension = lazy(() => import('../../container/pages/EditComprehension'));
// // const AddQuestionsType = lazy(() => import('../../container/pages/AddQuestionsType'));
// //******************* Questions Bank Ends ********************************************/

// // const Board = lazy(() => import('../../components/PinterestBoard/TrelloApp'));
// // const NewBoard = lazy(() => import('../../ComponentsTrello/BoardApp'));
// const Schedule = lazy(() => import('../../components/Schedules/TrelloApp'));
// const Pin = lazy(() => import('../../container/pages/Pin'));

// const CustomerList = lazy(() => import('../../container/pages/CustomerList'));
// const AddCustomer = lazy(() => import('../../container/pages/AddCustomer'));
// const ViewCustomer = lazy(() => import('../../container/pages/ViewCustomer'));
// const EditCustomer = lazy(() => import('../../container/pages/EditCustomer'));

// const ChatGpt = lazy(() => import('../../container/pages/GeneralSetting'));
// const Configuration = lazy(() => import('../../container/pages/Configuration'));
// const FormUI = lazy(() => import('../../container/pages/FormUI'));

// const SocialAccounts = lazy(() => import('../../container/pages/SocialAccounts'));

// const Students = lazy(() => import('../../container/pages/Students'));
// const Parents = lazy(() => import('../../container/pages/Parents'));
// const Teachers = lazy(() => import('../../container/pages/Teachers'));
// const ClassWork = lazy(() => import('../../container/pages/ClassWork'));
// const VideoBank = lazy(() => import('../../container/pages/VideoBank.js'));
// const CreateVideo = lazy(() => import('../../container/pages/CreateVideos'));
// const PinList = lazy(() => import('../../container/pages/PinList'));
// const LoginNew = lazy(() => import('../admin/LoginNew'));
// const Signupcategory = lazy(() => import('../admin/Signupcategory'));
// const CreateUser = lazy(() => import('../admin/CreateUser'));

// const Board = lazy(() => import('../../components/PinterestBoard/TrelloApp'));
// const AddDifficultyLevel = lazy(() => import('../../container/pages/AddDifficultyLevel'));
// const CreatedLessons = lazy(() => import('../../container/pages/CreatedLessons'));
// const EditLessons = lazy(() => import('../../container/pages/EditLessons'));
// const EditVideos = lazy(() => import('../../container/pages/EditVideos'));
// const EditPractice = lazy(() => import('../../container/pages/EditPractice'));
// const AddMatchQuestions = lazy(() => import('../../container/pages/AddMatchQuestions'));
// const ImportUsers = lazy(() => import('../../container/pages/ImportUsers'));
// const CoursesList = lazy(() => import('../../container/pages/CoursesList'));
// const CreateCourses = lazy(() => import('../../container/pages/CreateNewCourse'));
// //
// const Analysis = lazy(() => import('../../container/pages/Analysis'));
// const NewAnalysis = lazy(() => import('../../container/pages/NewAnalysis'));
// const ViewResults = lazy(() => import('../../container/pages/ViewResults'));
// const Consultation = lazy(() => import('../../container/pages/ConsultationRequests'));
// //

const DashboardRoutes = () => {
  const { path } = useRouteMatch();
  const site_detail = Cookies.get('site_detail');
  if (site_detail) {
    window.location.href = '/';
  }
  return (
    <Switch>
      {/* 1 */}
      <Route exact path={path} component={Dashboard} />
      <Route path={`${path}/social`} component={Dashboard} />
      <Route exact path={`${path}/eco`} component={Ecommerce} />
      <Route exact path={`${path}/business`} component={Business} />
      <Route exact path={`${path}/performance`} component={Performance} />
      <Route exact path={`${path}/crm`} component={CRM} />
      <Route exact path={`${path}/admin`} component={AddPins} />

      {/* 2 */}
      <Route path={`${path}/add-user`} component={AddUser} />
      {/* <Route path={`${path}/view-user/:id`} component={ViewUser} /> */}
      <Route path={`${path}/edit-user/:id`} component={EditUser} />
      <Route path={`${path}/view-user/:id`} component={ViewUser} />

      <Route path={`${path}/userlist`} component={UserList} />
      <Route path={`${path}/products`} component={ProductList} />
      <Route path={`${path}/add-product`} component={AddProduct} />
      <Route path={`${path}/edit-product/:id`} component={EditProduct} />
      <Route path={`${path}/view-product/:id`} component={ViewProduct} />

      <Route path={`${path}/entity/:id`} component={EntityList} />
      <Route path={`${path}/add-entity/:id`} component={AddEntity} />
      <Route path={`${path}/edit-entity/:id`} component={EditEntity} />

      {/* Car Catalog */}
      <Route path={`${path}/make`} component={MakeList} />
      <Route path={`${path}/add-make`} component={AddMake} />
      <Route path={`${path}/add-base`} component={AddBase} />
      <Route path={`${path}/add-body`} component={AddBody} />
      <Route path={`${path}/add-model`} component={AddModel} />
      <Route path={`${path}/add-submodel`} component={AddSubModel} />
      <Route path={`${path}/add-engine`} component={AddEngine} />
      <Route path={`${path}/add-transmission`} component={AddTransmission} />

      <Route path={`${path}/edit-make/:id`} component={EditMake} />
      <Route path={`${path}/edit-base/:id`} component={EditBase} />
      <Route path={`${path}/edit-body/:id`} component={EditBody} />
      <Route path={`${path}/edit-submodel/:id`} component={EditSubModel} />
      <Route path={`${path}/edit-model/:id`} component={EditModel} />
      <Route path={`${path}/edit-engine/:id`} component={EditEngine} />
      <Route path={`${path}/edit-transmission/:id`} component={EditTransmission} />
      <Route path={`${path}/model`} component={ModelList} />
      <Route path={`${path}/submodel`} component={SubModelList} />
      <Route path={`${path}/base`} component={BaseList} />
      <Route path={`${path}/engine`} component={EngineList} />
      <Route path={`${path}/transmission`} component={Transmission} />
      <Route path={`${path}/body`} component={BodyList} />

      <Route path={`${path}/subscriptions-plans`} component={SubscriptionPlans} />
      <Route path={`${path}/add-subscriptions-plans`} component={AddSubscriptionPlan} />
      {/* <Route path={`${path}/edit-subscriptions-plans/:id`} component={EditSubscriptionPlan} /> */}

      <Route path={`${path}/subscriptions`} component={SubscribedUsers} />
      <Route path={`${path}/add-subscribed-user`} component={AddSubscribedUser} />
      <Route path={`${path}/edit-subscribed-user/:id`} component={EditSubscribedUser} />
      <Route path={`${path}/view-subscribed-user/:id`} component={ViewSubscribedUser} />

      <Route path={`${path}/categories`} component={CategoryList} />
      <Route path={`${path}/add-category`} component={AddCategoryList} />
      <Route path={`${path}/view-category/:id`} component={ViewCategoryList} />
      <Route path={`${path}/edit-category/:id`} component={EditCategoryList} />

      <Route path={`${path}/sub-category`} component={SubCategoryList} />
      <Route path={`${path}/add-subcategory`} component={AddSubCategory} />
      {/* Coupon & Brand */}
      <Route path={`${path}/coupon-deals`} component={CouponDealsList} />
      <Route path={`${path}/add-coupon-deals`} component={AddCouponDeals} />
      <Route path={`${path}/view-coupon-deals/:id`} component={ViewCouponDeals} />
      <Route path={`${path}/edit-coupon-deals/:id`} component={EditCouponDeals} />
      {/* Order */}
      <Route path={`${path}/order`} component={Order} />
      <Route path={`${path}/add-order`} component={AddOrder} />

      {/* Customers */}
      <Route path={`${path}/customer`} component={Customers} />
      <Route path={`${path}/add-customer`} component={AddCustomers} />
      <Route path={`${path}/view-customer/:id`} component={ViewCustomers} />
      <Route path={`${path}/edit-customer/:id`} component={AddCustomers} />

      <Route path={`${path}/sellers`} component={Sellers} />
      <Route path={`${path}/add-seller`} component={AddSellers} />
      <Route path={`${path}/view-seller/:id`} component={ViewSellers} />
      <Route path={`${path}/edir-seller/:id`} component={EditSellers} />

      {/* Pages */}
      <Route path={`${path}/pages`} component={Pages} />
      <Route path={`${path}/add-pages`} component={AddPages} />
      <Route path={`${path}/view-pages/:id`} component={ViewPages} />
      <Route path={`${path}/edit-pages/:id`} component={EditPages} />

      {/* Ticket */}
      <Route path={`${path}/ticketlist`} component={Ticket} />
      <Route path={`${path}/add-ticket`} component={AddTicket} />
      <Route path={`${path}/view-ticket/:id`} component={ViewTicket} />
      <Route path={`${path}/edit-ticket/:id`} component={EditTicket} />

      {/* Role & Permissions */}
      <Route path={`${path}/permissions`} component={Roles_Permissions} />
      <Route path={`${path}/add-user-role`} component={AddUsersRole} />

      {/* Refferals */}
      <Route path={`${path}/refferals`} component={Refferals} />

      {/* FAQ */}
      <Route path={`${path}/faqlist`} component={FaqList} />
      <Route path={`${path}/add-faq`} component={AddFaq} />
      <Route path={`${path}/view-faq/:id`} component={ViewFaq} />
      <Route path={`${path}/edit-faq/:id`} component={EditFaq} />

      {/* Settings */}
      <Route path={`${path}/general-settings`} component={GeneralSetting} />
      <Route path={`${path}/local-settings`} component={LocalSettings} />
      <Route path={`${path}/home-settings`} component={HomePageSetting} />
      <Route path={`${path}/email-settings`} component={EmailSetting} />
      <Route path={`${path}/payment-settings`} component={PaymentSetting} />
      <Route path={`${path}/billandtax-settings`} component={BillandTaxSetting} />
      <Route path={`${path}/theme-settings`} component={ThemeSetting} />
      <Route path={`${path}/blog`} component={Blog} />
      <Route path={`${path}/addblog`} component={AddBlog} />
      <Route path={`${path}/editblog/:id`} component={EditBlog} />
      {/* 2 ENGAGE*/}

      {/* Brand */}
      <Route path={`${path}/brand`} component={BrandList} />
      <Route path={`${path}/add-brand`} component={AddBrand} />
      <Route path={`${path}/edit-brand/:id`} component={EditBrand} />

      {/* platform */}
      <Route path={`${path}/catalog`} component={PlatformList} />
      <Route path={`${path}/add-catalog`} component={AddPlatform} />
      <Route path={`${path}/edit-catalog/:id`} component={EditPlatform} />
      <Route path={`${path}/view-catalog/:id`} component={ViewPlatform} />

      {/* Ads */}
      <Route path={`${path}/ads`} component={AdsList} />
      <Route path={`${path}/add-ads`} component={AddAds} />
      <Route path={`${path}/edit-ads/:id`} component={EditAds} />

      <Route path={`${path}/domain`} component={Domain} />

      {/* Banners */}
      <Route path={`${path}/banners`} component={BannersList} />

      <Route path={`${path}/add-banners`} component={AddBanners} />
      <Route path={`${path}/edit-banners/:id`} component={EditBanners} />

      {/* logo */}
      <Route path={`${path}/logo`} component={LogoList} />
      <Route path={`${path}/add-logo`} component={AddLogo} />
      <Route path={`${path}/edit-logo/:id`} component={EditLogo} />

      {/* Meta Home */}
      <Route path={`${path}/meta-home`} component={MetaHomeList} />
      <Route path={`${path}/add-meta-home`} component={AddMetaHome} />
      <Route path={`${path}/edit-meta-home/:id`} component={EditMetaHome} />
      {/* <Route path={`${path}/view-meta-home/:id`} component={ViewMetaHome} /> */}

      {/* Start Manage Tests */}
      {/*       
      <Route path={`${path}/engage/quizzes`} component={QuizzesList} />
      <Route path={`${path}/engage/create`} component={CreateNewQuiz} />
      <Route path={`${path}/engage/edit-quiz/:id`} component={EditNewQuiz} />
      <Route path={`${path}/configuration/add-page`} component={AddNewPage} />
      <Route path={`${path}/configuration/edit-page/:id`} component={EditNewPage} />
      <Route path={`${path}/configuration/view-page/:id`} component={ViewNewPage} />
      <Route path={`${path}/engage/exam-type`} component={ExamType} />
      <Route path={`${path}/library/add-squence-questions/:id`} component={AddSquenceQuestion} />
      <Route path={`${path}/library/add-fillblanks/:id`} component={AddFillTheBlanks} />
      <Route path={`${path}/library/add-muliple-questions/:id`} component={AddMultipleQuestions} />
      <Route path={`${path}/engage/overallQuiz-report/:id`} component={OverAllReportQuiz} />
      <Route path={`${path}/engage/overallpractice-report/:id`} component={OverAllReportPractice} />
      <Route path={`${path}/engage/quizDetailed_report/:id`} component={QuizDetailedReport} />
      <Route path={`${path}/engage/quiz-type`} component={QuizType} />
      <Route path={`${path}/engage/create-exam`} component={CreateNewExam} />
      <Route path={`${path}/engage/edit-exam/:id`} component={EditNewExam} />
      <Route path={`${path}/engage/overallExam-report/:id`} component={OverAllReportExam} />
      <Route path={`${path}/engage/examDetailed_report/:id`} component={ExamDetailedReport} />
      <Route path={`${path}/engage/exams`} component={ExamList} />


      <Route path={`${path}/engage/practice-sets`} component={PracticeSets} />
      <Route path={`${path}/engage/createpractice`} component={CreatePractice} />
      <Route path={`${path}/engage/configure-lessons`} component={CreatePracticeLesson} />
      <Route path={`${path}/engage/createpracticelesson`} component={CreatePracticeLesson} />
      <Route path={`${path}/engage/configure-videos`} component={CreatePracticeVideo} />
      <Route path={`${path}/engage/createpracticevideo`} component={CreatePracticeVideo} />
      <Route path={`${path}/engage/edit-practice/:id`} component={EditPractice} />
      <Route path={`${path}/engage/courses`} component={CoursesList} />
      <Route path={`${path}/engage/create-course`} component={CreateCourses} />

      <Route exact path={`${path}/engage/Pinterest`} component={Pin} />
      <Route exact path={`${path}/engage/schedule`} component={Schedule} />
      <Route exact path={`${path}/engage/Board`} component={Board} />
      <Route path={`${path}/engage/pin-list`} component={PinList} />

      <Route path={`${path}/library/question`} component={QuestionList} />
      <Route path={`${path}/library/add-singlequestion/:id`} component={AddQuestionsSingle} />
      <Route path={`${path}/library/edit-questions/:id`} component={EditQuestions} />
      <Route path={`${path}/library/add-shortquestions/:id`} component={Addshortquestion} />
      <Route path={`${path}/library/add-questiontype`} component={AddQuestionType} />
      <Route path={`${path}/library/edit-questiontype/:id`} component={EditQuestionsType} />
      <Route path={`${path}/library/import-questions`} component={ImportQuestions} />
      <Route path={`${path}/library/comprehension`} component={ComprehensionsList} />
      <Route path={`${path}/library/question-types`} component={QuestionsTypes} />
      <Route path={`${path}/library/edit-comprehension/:id`} component={EditComprehension} />
      <Route path={`${path}/library/add-matchquestions/:id`} component={AddMatchQuestions} />
   
      <Route path={`${path}/library/lessons`} component={LessionsBank} />
      <Route path={`${path}/library/editlesson/:id`} component={EditLessons} />
      <Route path={`${path}/library/createlesson`} component={CreatedLessons} />

      <Route path={`${path}/library/videos`} component={VideoBank} />
      <Route path={`${path}/library/editvideos/:id`} component={EditVideos} />
      <Route path={`${path}/library/createVideo`} component={CreateVideo} />

 
      <Route path={`${path}/configuration/plans`} component={Plans} />
      <Route path={`${path}/configuration/pages`} component={Pages} />

      <Route path={`${path}/configuration/payments`} component={Payments} />

      <Route path={`${path}/configuration/users`} component={UsersList} />

      <Route path={`${path}/configuration/create-new-user`} component={CreateUser} />
      <Route path={`${path}/configuration/usergroup`} component={UserGroup} />
      <Route path={`${path}/configuration/add-user`} component={AddUser} />
      <Route path={`${path}/configuration/add-role`} component={AddUserRole} />
      <Route path={`${path}/configuration/roles-permissions`} component={Roles_Permissions} />
      <Route path={`${path}/configuration/import-users`} component={ImportUsers} />

      <Route path={`${path}/configuration/categorieslist`} component={CategoryList} />
      <Route path={`${path}/configuration/sub-category`} component={SubCategory} />
      <Route path={`${path}/configuration/tags`} component={TagsList} />

      <Route path={`${path}/configuration/sectionlist`} component={SectionList} />
      <Route path={`${path}/configuration/skill-list`} component={SkillList} />
      <Route path={`${path}/configuration/topiclist`} component={TopicList} />


      

      <Route path={`${path}/students`} component={Students} />
      <Route path={`${path}/parents`} component={Parents} />
      <Route path={`${path}/teachers`} component={Teachers} />
      <Route path={`${path}/class-work`} component={ClassWork} />
      <Route path={`${path}/student`} component={StudentDashboard} />
      <Route path={`${path}/teacher`} component={TeacherDashboard} />
      <Route path={`${path}/studenthome`} component={StudentHome} />
      <Route path={`${path}/tutoring`} component={Tutoring} />
      <Route path={`${path}/exam`} component={Exam} />
      <Route path={`${path}/PastPapers`} component={PastPapers} />
      <Route path={`${path}/AllCourses`} component={AllCourses} />
      <Route path={`${path}/activeplan`} component={ActivePlan} />
      <Route path={`${path}/blog`} component={Blog} />
      <Route path={`${path}/addblog`} component={AddBlog} />
      <Route path={`${path}/editblog/:id`} component={EditBlog} />


      <Route path={`${path}/add-customer`} component={AddCustomer} />
      <Route path={`${path}/view-customer/:id`} component={ViewCustomer} />
      <Route path={`${path}/edit-customer/:id`} component={EditCustomer} />
      <Route exact path={`${path}/social_accounts`} component={SocialAccounts} />
      <Route path={`${path}/chatgpt`} component={ChatGpt} />
      <Route path={`${path}/configuration`} component={Configuration} />
      <Route path={`${path}/form-builder`} component={FormUI} />
      <Route path={`${path}/login-new`} component={LoginNew} />
      <Route path={`${path}/signup-category`} component={Signupcategory} />
      <Route path={`${path}/add-post`} component={AddPost} />
      <Route path={`${path}/edit-post/:id`} component={EditPost} />
      <Route path={`${path}/view-post/:id`} component={ViewPost} />

      <Route path={`${path}/default-link`} component={DefaultLink} />
      <Route path={`${path}/edit-pins/:id`} component={EditPins} />
      <Route path={`${path}/view-pins/:id`} component={ViewPins} />

      <Route path={`${path}/edit-user/:id`} component={EditUser} />
      <Route path={`${path}/view-user/:id`} component={ViewUser} />

   
      <Route path={`${path}/Analysis/:id`} component={Analysis} />
      <Route path={`${path}/NewAnalysis/:id`} component={NewAnalysis} />
      <Route path={`${path}/viewResults`} component={ViewResults} />
      <Route path={`${path}/consultation`} component={Consultation} /> */}
    </Switch>
  );
};

export default DashboardRoutes;
