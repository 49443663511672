import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { hot } from 'react-hot-loader/root';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import Admin from './routes/admin'; //calling admin
import Auth from './routes/auth'; //calling auth
import Exam from './routes/ExamURL';
//calling auth
import './static/css/styleNew.css';
import './static/css/style.css';
import config from './config/config'; //config-------------------------------1
import store from './redux/store'; //calling store---------------------------2
import ProtectedRoute from './components/utilities/protectedRoute';

Axios.defaults.withCredentials = true;
const { theme } = config;
var ExamUrl;
var ExamURL1;

if (window.location.host != 'localhost:3003') {
  if (window.location.protocol.toLowerCase() !== 'https:') {
    const url = `https://${window.location.host}`;
    window.location.replace(url);
  }
}
const ProviderConfig = () => {
  const { rtl, isLoggedIn, topMenu, darkMode } = useSelector(state => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      isLoggedIn: state.auth.login,
    };
  });

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    return () => (unmounted = true);
  }, [setPath]);

  useEffect(() => {}, []);
  const splitedURL = window.location.pathname.split('/');
  var PathArray = [
    'about',
    'blog',
    'services',
    'contact',
    'portfolio',
    'privacy-policy',
    'pickup',
    'login',
    'my-account',
    'notification',
    'Edit_Profile',
    'log-in',
    'forgotpassword',
    'verifiedotp',
    'listing-details',
    'search',
    'blog',
    'faq',
    'terms-of-service',
    'oe-academy',
    'motorsports-program',
    'we-are-hiring',
    'guarantee',
    'shipping-policy',
    'deal-and-coupons',
    'wishlist',
    'profile_seller',
    'resetpassword',
    'listing',
    'register',
    'amazon',
    'flipkart',
    'myntra',
    'coupons',
    'coupons-search',
    'search-details',
  ];

  if (PathArray.includes(splitedURL?.[1])) {
    ExamUrl = 'home';
  }

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        {ExamUrl != 'home' ? (
          <Router basename={process.env.PUBLIC_URL}>
            {!isLoggedIn ? <Route path="/" component={Auth} /> : <ProtectedRoute path="/dashboard" component={Admin} />}
            {isLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && (
              <>
                <Redirect to="/dashboard" />
                <Route path="/" component={Auth} />
              </>
            )}
          </Router>
        ) : (
          <Router basename={process.env.PUBLIC_URL}>
            <Route path="/" component={Exam} />
          </Router>
        )}
      </ThemeProvider>
    </ConfigProvider>
  );
};
function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default hot(App);
