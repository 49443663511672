import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import iconDashboard from './../static/img/Home/icon-dashboard.png';
import icontutoring from './../static/img/Home/icon-tutoring.png';
import iconcourses from './../static/img/Home/icon-courses.png';
import iconanalysis from './../static/img/Home/icon-analysis.png';

const { decrypt } = require('../helpers/encryption-decryption');
const domainpath = process.env.REACT_APP_DOMAIN_ENDPOINT;

const { SubMenu } = Menu;
var UserRole = [];

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length >= 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );
  const [RoleName, setRoleName] = useState();
  const [userRoleName, setUserRoleName] = useState();
  var LoginAsChild = Cookies.get('LoginAsChild');
  const SuperAdminDetails = Cookies.get('SuperAdminDetails');
  useEffect(() => {
    var enc_userDetail = Cookies.get('UserDetail');
    var response = decrypt(enc_userDetail);
    console.log(response);
    if (response?.login == true) {
      const UserInfo = response?.sessdata?.user?.[0];
      const GetRole = UserInfo?.user_role?.toUpperCase();
      setUserRoleName(GetRole);
      setRoleName(GetRole);
      if (UserInfo?.permissions != 'No Permission for role') {
        const modules = UserInfo?.permissions?.[GetRole].MODULES;
        UserRole = modules;
        console.log(UserRole);
      }
    }
  }, []);

  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const LoginAsSuperAdmin = () => {
    if (SuperAdminDetails != undefined) {
      Cookies.set('UserDetail', SuperAdminDetails);
      Cookies.remove('LoginAsChild');
      Cookies.remove('SuperAdminDetails');
      Cookies.remove('ChildByParent');
      // history.push(`../dashboard`);

      setTimeout(() => {
        history.push(`dashboard/admin`);
        window.location.reload();
      }, 500);
    }
  };
  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // defaultSelectedKeys={
      //   !topMenu
      //     ? [
      //         `${
      //           mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
      //         }`,
      //       ]
      //     : []
      // }
      // defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      defaultSelectedKeys={openKeys}
      defaultOpenKeys={openKeys}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
      gutter={30}
      className="custmblock"
    >
      {UserRole != undefined ? (
        <>
          {UserRole['DASHBOARD'] !== undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink
                    className="menuItem-iocn"
                    // to={`${path}/customer-lislt`}
                    to={'/dashboard'}
                  >
                    {/* <FeatherIcon icon="home" /> */}
                    <img src={iconDashboard} />
                  </NavLink>
                )
              }
              key="dashboard"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/admin`}>
                Dashboard
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}
          {UserRole['USERS'] !== undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/userlist`}>
                    <FeatherIcon icon="users" />
                  </NavLink>
                )
              }
              key="userlist"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/userlist`}>
                Users
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}
          {/* {UserRole['USERS'] !== undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/userlist`}>
                    <FeatherIcon icon="users" />
                  </NavLink>
                )
              }
              key="Customers"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/customer`}>
                Customers
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}
          {UserRole['USERS'] !== undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/userlist`}>
                    <FeatherIcon icon="users" />
                  </NavLink>
                )
              }
              key="sellers"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/sellers`}>
                Sellers
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )} */}
          {/* {UserRole['USERS'] !== undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/userlist`}>
                    <FeatherIcon icon="users" />
                  </NavLink>
                )
              }
              key="Enquiry"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/userlist`}>
                Enquiry
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )} */}

          {/* {UserRole['SUBSCRIPTIONS'] !== undefined ? (
            <SubMenu
              className="subscription_style"
              key={openKeys[0] == 'subscriptions-plans' ? 'subscriptions-plans' : 'subscriptions'}
              icon={!topMenu && <FeatherIcon icon="airplay" />}
              title="Subscriptions"
            >
              <>
                {UserRole['SUBSCRIPTION PLANS'] !== undefined ? (
                  <Menu.Item key="subscriptions-plans">
                    <NavLink onClick={toggleCollapsed} to={`${path}/subscriptions-plans`} key="subscriptions-plans">
                      Plans
                    </NavLink>
                  </Menu.Item>
                ) : (
                  ''
                )}
                {UserRole['SUBSCRIPTION USERS'] !== undefined ? (
                  <Menu.Item key="subscriptions">
                    <NavLink onClick={toggleCollapsed} to={`${path}/subscriptions`}>
                      Users
                    </NavLink>
                  </Menu.Item>
                ) : (
                  ''
                )}
              </>
            </SubMenu>
          ) : (
            ''
          )} */}
          {/* <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/blog`}>
                  <FeatherIcon icon="plus" />
                </NavLink>
              )
            }
            key="sub"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/subscriptions`}>
              subscriptions
            </NavLink>
          </Menu.Item> */}
          {UserRole['PRODUCTS'] !== undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/products`}>
                    <FeatherIcon icon="box" />
                  </NavLink>
                )
              }
              key="products"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/products`}>
                Listing
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}
          {UserRole['PRODUCTS'] !== undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/catalog`}>
                    <FeatherIcon icon="box" />
                  </NavLink>
                )
              }
              key="make"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/catalog`}>
                Platform
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}

          {UserRole['PRODUCTS'] !== undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/brand`}>
                    <FeatherIcon icon="bold" />
                  </NavLink>
                )
              }
              key="brand"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/brand`}>
                Brand
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}
          {UserRole['PRODUCTS'] !== undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/domain`}>
                    <FeatherIcon icon="activity" />
                  </NavLink>
                )
              }
              key="domain"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/domain`}>
                Domain
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}

          {UserRole['PRODUCTS'] !== undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/coupon-deals`}>
                    <FeatherIcon icon="activity" />
                  </NavLink>
                )
              }
              key="coupon-deals"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/coupon-deals`}>
                Coupon & Deals
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}
          {/* {UserRole['USERS'] != undefined ? (
            <>
              <SubMenu icon={!topMenu && <FeatherIcon icon="clipboard" />} title="Car Catalog">
                <>
                  {UserRole['CATEGORIES'] !== undefined ? (
                    <>
                      <Menu.Item key="make">
                        <NavLink onClick={toggleCollapsed} to={`${path}/add-make`}>
                          Car Catalog
                        </NavLink>
                      </Menu.Item>

                      <Menu.Item key="model">
                        <NavLink onClick={toggleCollapsed} to={`${path}/model`}>
                          Model
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item key="sub-model">
                        <NavLink onClick={toggleCollapsed} to={`${path}/submodel`}>
                          Sub Model
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item key="base">
                        <NavLink onClick={toggleCollapsed} to={`${path}/base`}>
                          Base
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item key="body">
                        <NavLink onClick={toggleCollapsed} to={`${path}/body`}>
                          Body
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item key="engine">
                        <NavLink onClick={toggleCollapsed} to={`${path}/engine`}>
                          Engine
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item key="transmission">
                        <NavLink onClick={toggleCollapsed} to={`${path}/transmission`}>
                          Transmission
                        </NavLink>
                      </Menu.Item>
                    </>
                  ) : (
                    ''
                  )}
                </>
              </SubMenu>
            </>
          ) : (
            ''
          )} */}
          {/* {UserRole['CATEGORIES'] !== undefined ? (
            <SubMenu
              icon={!topMenu && <FeatherIcon icon="briefcase" />}
              title="Categories"
              key={openKeys[0] == 'categories' ? 'categories' : openKeys[0] == 'sub-category' ? 'sub-category' : ''}
            >
              <>
                {UserRole['CATEGORIES'] !== undefined ? (
                  <>
                    <Menu.Item key="categories">
                      <NavLink onClick={toggleCollapsed} to={`${path}/categories`}>
                        Categories
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="sub-category">
                      <NavLink onClick={toggleCollapsed} to={`${path}/sub-category`}>
                        Sub-Categories
                      </NavLink>
                    </Menu.Item>
                  </>
                ) : (
                  ''
                )}
              </>
            </SubMenu>
          ) : (
            ''
          )} */}
          {UserRole['CATEGORIES'] !== undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/categories`}>
                    <FeatherIcon icon="briefcase" />
                  </NavLink>
                )
              }
              key="categories"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/categories`}>
                Categories
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}
          {UserRole['PAGES'] !== undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/pages`}>
                    <FeatherIcon icon="file-text" />
                  </NavLink>
                )
              }
              key="pages"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/pages`}>
                Pages
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}
          {/* {UserRole['TICKETS'] !== undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/ticketlist`}>
                    <FeatherIcon icon="tag" />
                  </NavLink>
                )
              }
              key="ticket"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/ticketlist`}>
                Tickets
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )} */}
          {UserRole['USERS'] != undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/blog`}>
                    <FeatherIcon icon="message-square" />
                  </NavLink>
                )
              }
              key="blog"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/blog`}>
                Blog
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}
          {UserRole['USERS'] != undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/ads`}>
                    <FeatherIcon icon="message-square" />
                  </NavLink>
                )
              }
              key="ads"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/ads`}>
                Ads
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}
          {/* {UserRole['EMAIL TEMPLATES'] !== undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/blog`}>
                    <FeatherIcon icon="mail" />
                  </NavLink>
                )
              }
              key="email"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/blog`}>
                Email Templates
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )} */}
          {/* {UserRole['CUSTOMERS'] !== undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/blog`}>
                    <FeatherIcon icon="user" />
                  </NavLink>
                )
              }
              key="customer"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/customer`}>
                Customers
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )} */}
          {/* {UserRole['REFFERALS'] !== undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/blog`}>
                    <FeatherIcon icon="dollar-sign" />
                  </NavLink>
                )
              }
              key="refferal"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/refferals`}>
                Refferals
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )} */}
          {/* {UserRole['FAQ'] !== undefined ? (
            <SubMenu key="faq" icon={!topMenu && <FeatherIcon icon="help-circle" />} title="FAQ">
              <>
                {UserRole['FAQ'] !== undefined ? (
                  <Menu.Item key="faq">
                    <NavLink onClick={toggleCollapsed} to={`${path}/faqlist`}>
                      FAQ
                    </NavLink>
                  </Menu.Item>
                ) : (
                  ''
                )}
              </>
            </SubMenu>
          ) : (
            ''
          )} */}
          {/* {UserRole['ROLES & PERMISSIONS'] !== undefined ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/permissions`}>
                    <FeatherIcon icon="smile" />
                  </NavLink>
                )
              }
              key="permissions"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/permissions`}>
                Role & Permissions
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )} */}

          {UserRole['USERS'] !== undefined ? (
            <SubMenu
              key={
                openKeys[0] == 'meta-home'
                  ? 'meta-home'
                  : openKeys[0] == 'banners'
                  ? 'banners'
                  : openKeys[0] == 'logo'
                  ? 'logo'
                  : // : openKeys[0] == 'email-settings'
                    // ? 'email-settings'
                    // : openKeys[0] == 'theme-settings'
                    // ? 'theme-settings'
                    ''
              }
              icon={!topMenu && <FeatherIcon icon="settings" />}
              title="Settings"
            >
              <>
                {UserRole['USERS'] !== undefined ? (
                  <Menu.Item key="meta-home">
                    <NavLink onClick={toggleCollapsed} to={`${path}/meta-home`}>
                      Mete Home
                    </NavLink>
                  </Menu.Item>
                ) : (
                  ''
                )}
                {UserRole['USERS'] !== undefined ? (
                  <Menu.Item key="banners">
                    <NavLink onClick={toggleCollapsed} to={`${path}/banners`}>
                      Banners
                    </NavLink>
                  </Menu.Item>
                ) : (
                  ''
                )}

                {UserRole['USERS'] !== undefined ? (
                  <Menu.Item key="logo">
                    <NavLink onClick={toggleCollapsed} to={`${path}/logo`}>
                      Logo
                    </NavLink>
                  </Menu.Item>
                ) : (
                  ''
                )}

                {/* {UserRole['EMAIL SETTINGS'] !== undefined ? (
                  <Menu.Item key="email-settings">
                    <NavLink onClick={toggleCollapsed} to={`${path}/email-settings`}>
                      Email Settings
                    </NavLink>
                  </Menu.Item>
                ) : (
                  ''
                )}
                {UserRole['PAYMENTS SETTINGS'] !== undefined ? (
                  <Menu.Item key="payment-settings">
                    <NavLink onClick={toggleCollapsed} to={`${path}/payment-settings`}>
                      Payment Settings
                    </NavLink>
                  </Menu.Item>
                ) : (
                  ''
                )}
                {UserRole['BILL & TAX SETTINGS'] !== undefined ? (
                  <Menu.Item key="billandtax-settings">
                    <NavLink onClick={toggleCollapsed} to={`${path}/billandtax-settings`}>
                      Bill & Tax Settings
                    </NavLink>
                  </Menu.Item>
                ) : (
                  ''
                )}
                {UserRole['THEME SETTINGS'] !== undefined ? (
                  <Menu.Item key="theme-settings">
                    <NavLink onClick={toggleCollapsed} to={`${path}/theme-settings`}>
                      Theme Settings
                    </NavLink>
                  </Menu.Item>
                ) : (
                  ''
                )} */}
              </>
            </SubMenu>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
