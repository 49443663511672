import React, { lazy, Suspense } from 'react';
import { Switch, Route, Routes as Router, Redirect, useRouteMatch } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';
import Navbar from '../components/Pages/Navbaar';
import Cookies from 'js-cookie';
const UserDetail = Cookies.get('access_token');
const About = lazy(() => import('../components/Pages/About'));
const Blog = lazy(() => import('../components/Pages/Blog'));
const FAQ = lazy(() => import('../components/Pages/Faq'));
const TermsOfService = lazy(() => import('../components/Pages/TermsAndService'));
const OeAcademy = lazy(() => import('../components/Pages/OE_Academy'));
const Profile_seller = lazy(() => import('../components/Pages/Profile_seller'));
const MotorsportsProgram = lazy(() => import('../components/Pages/MotorsportsProgram'));
const WeAreHiring = lazy(() => import('../components/Pages/WeAreHiring'));
const Guarantee = lazy(() => import('../components/Pages/Guarantee'));
const ShippingPolicy = lazy(() => import('../components/Pages/ShippingPolicy'));
const DealAndCoupons = lazy(() => import('../components/Pages/DealAndCoupons'));
const Wishlist = lazy(() => import('../components/Pages/Wishlist'));

const BlogSingleDetails = lazy(() => import('../components/Pages/BlogSingleDetails'));

// const PrivacyPolicy = lazy(() => import('../components/Pages/PrivacyPolicy'));
const Services = lazy(() => import('../components/Pages/services'));
const Contact = lazy(() => import('../components/Pages/Contact'));
const Portfolio = lazy(() => import('../components/Pages/Portfolio'));
const PrivacyPolicy = lazy(() => import('../components/Pages/Privacy_Policy'));
const Pickup = lazy(() => import('../components/Pages/Pickup'));
const Insurance = lazy(() => import('../components/Pages/Insurance'));
const Edit_Profile = lazy(() => import('../components/Pages/Edit_Profile'));
const Notification = lazy(() => import('../components/Pages/Notification'));
const Order_history = lazy(() => import('../components/Pages/Order_history'));
const Subscription = lazy(() => import('../components/Pages/Subscription'));
const Checkout_new = lazy(() => import('../components/Pages/Checkout_new'));
const CheckOut = lazy(() => import('../container/pages/Checkout'));
// const Account_details = lazy(() => import('../components/Pages/Account_details'));
const HomepageNew = lazy(() => import('../container/pages/HomepageNew'));
const Login = lazy(() => import('../container/profile/authentication/overview/SignIn'));
const OneTimePass = lazy(() => import('../container/profile/authentication/overview/OneTimePassword'));
const LoginNew = lazy(() => import('../components/Pages/Loginnew'));
const ForgotPass = lazy(() => import('../container/profile/authentication/overview/ForgotPassword'));
const ListingDetails = lazy(() => import('../components/Pages/ListingDetails'));
const ResetPass = lazy(() => import('../container/profile/authentication/overview/ResetPassword'));
const Listing = lazy(() => import('../components/Pages/Listings'));
const ListingAll = lazy(() => import('../components/Pages/ListingsAll'));
const Register = lazy(() => import('../components/Pages/Register'));
const Amazon_Deals = lazy(() => import('../components/Pages/Amazon_Deals'));
const Flipkart_Deals = lazy(() => import('../components/Pages/Flipkart_Deals'));
const Myntra_Deals = lazy(() => import('../components/Pages/Myntra_Deals'));
const Coupons = lazy(() => import('../components/Pages/Coupons'));
const CouponSearch = lazy(() => import('../components/Pages/CouponSearch'));
const SearchDetails = lazy(() => import('../components/Pages/SearchDetails'));

const NotFound = () => {
  console.log('not found');
  // return <Redirect to="/" />;
};
const RedirectToLoging = () => {
  return <Redirect to="/log-in" />;
};
const FrontendRoutes = () => {
  console.log('frontend route');
  const { path } = useRouteMatch();
  return (
    <>
      {/* <Navbar /> */}
      <Switch>
        <Suspense fallback={<div id="cover-spin"></div>}>
          <Route exact path="/" component={HomepageNew} />
          <Route exact path="/listing-details/:id" component={ListingDetails} />
          <Route exact path="/search/" component={Listing} />
          <Route exact path="/listing/" component={ListingAll} />

          {/* <Route exact path={path} component={Dashboard} /> */}
          {/* <Route path={`${path}/about`} component={About} />
          <Route path={`${path}/blog`} component={Blog} />
          <Route path={`${path}/services`} component={Services} />
          <Route path={`${path}/contact`} component={Contact} />
          <Route path={`${path}/portfolio`} component={Portfolio} />
          <Route path={`${path}/pricing`} component={Pricing} /> */}
          <Route exact path="/log-in" component={LoginNew} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/forgotpassword" component={ForgotPass} />
          <Route exact path="/verifiedotp" component={OneTimePass} />
          <Route exact path="/about" component={About} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/blog/:id" component={BlogSingleDetails} />
          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/terms-of-service" component={TermsOfService} />
          <Route exact path="/oe-academy" component={OeAcademy} />
          <Route exact path="/motorsports-program" component={MotorsportsProgram} />
          <Route exact path="/we-are-hiring" component={WeAreHiring} />
          <Route exact path="/profile_seller/:id" component={Profile_seller} />
          <Route exact path="/resetpassword/:id" component={ResetPass} />
          <Route exact path="/amazon" component={Amazon_Deals} />
          <Route exact path="/flipkart" component={Flipkart_Deals} />
          <Route exact path="/myntra" component={Myntra_Deals} />
          <Route exact path="/coupons" component={Coupons} />
          <Route exact path="/coupons-search/" component={CouponSearch} />
          <Route exact path="/search-details/:id" component={SearchDetails} />

          <Route exact path="/guarantee" component={Guarantee} />
          <Route exact path="/shipping-policy" component={ShippingPolicy} />
          <Route exact path="/deal-and-coupons" component={DealAndCoupons} />
          <Route exact path="/wishlist" component={Wishlist} />
          <Route exact path="/checkout_new" component={Checkout_new} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/portfolio" component={Portfolio} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/Order_history" component={Order_history} />
          <Route exact path="/subscription" component={Subscription} />
          <Route exact path="/pickup" component={Pickup} />
          <Route exact path="/Insurance" component={Insurance} />
          <Route exact path="/Edit_Profile" component={Edit_Profile} />
          <Route exact path="/notification" component={Notification} />
          <Route exact path="/login" component={LoginNew} />
          <Route exact path="/checkout/:id" component={CheckOut} />
          {/* <Route exact path="/pricacy-policy" component={PrivacyPolicy} /> */}

          {/* {UserDetail ? <Route exact path="/my-account" component={Account_details} /> : ''} */}
          {/* <Route exact path="*" component={NotFound} /> */}

          {/* <Route exact path="/" component={HomepageNew} /> */}
          {/* <Route exact path="/exam/:id" component={Exam} />
        <Route exact path="/practice-sets/:id" component={PracticeSet} />
        <Route exact path="/quiz/:id" component={Quiz} />
        <Route exact path="/exam-success" component={ExamSuccess} />
        <Route exact path="/quiz-success" component={QuizSuccess} />
        <Route exact path="/practice-sets-success" component={PracticeSuccess} />
        <Route exact path="/" component={Home} />
        <Route exact path="/Analysis" component={Analysis} />
        <Route exact path="/viewResults" component={ViewResults} />
        <Route exact path="/overview-quiz/:id" component={OverViewQuiz} />
        <Route exact path="/overview-exam/:id" component={OverViewExam} />
        <Route exact path="/overview-practice-set/:id" component={OverViewPractice} /> */}
        </Suspense>
      </Switch>
    </>
  );
};

export default AuthLayout(FrontendRoutes);
